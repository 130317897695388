export default {
  sumValue (v, toFixed = 2) {
    if (!v || v === undefined || v === null || v.length <= 0) return 0
    return (v.reduce((a, b) => a + b)).toFixed(toFixed)
  },
  meanValue (v, toFixed = 2) { // v=array
    if (!v || v === undefined || v === null || v.length <= 0) return 0
    const len = v.length
    const mean = (v.reduce((a, b) => a + b) / len).toFixed(toFixed)
    return mean
  }
}
