import router from '../../router/index'

const state = {
  invSelectOptions: []
}

const mutations = {
  SET_INV_SELECT_OPTIONS (state, payload) {
    state.invSelectOptions = payload
  }
}

const actions = {
  setSiteInfoInvSelectOptions ({ commit }) {
    let invSelectOptions = []
    const invNum = parseInt(router.history.current.query.invLen)
    invSelectOptions = [...Array(invNum)].map((_item, idx) => ({
      value: idx + 1,
      text: `INV${(idx + 1).toString().padStart(2, '0')}`
    }))

    commit('SET_INV_SELECT_OPTIONS', invSelectOptions)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
