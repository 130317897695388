import Vue from 'vue'
import VueI18n from 'vue-i18n'
import tw from './langJson/tw'
import en from './langJson/en'
Vue.use(VueI18n)

let locale = 'tw'

const messages = {
  tw,
  en
}

const i18n = new VueI18n({
  /** 默认值 */
  locale,
  messages
})

export default i18n
