import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
import i18n from './lang/lang'
import VueCookies from 'vue-cookies'
import interceptors from './http'
import EasySlider from 'vue-easy-slider'
import VueExcelEditor from 'vue-excel-editor'
import { LayoutPlugin, TooltipPlugin, ModalPlugin, FormSelectPlugin, FormCheckboxPlugin } from 'bootstrap-vue'
import VueHighcharts from 'vue-highcharts'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/zh-tw'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueDarkMode from '@growthbunker/vuedarkmode'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2)
Vue.use(VueAxios, interceptors)
Vue.use(VueDarkMode)
Vue.use(VueExcelEditor)
Vue.use(EasySlider)
Vue.use(LayoutPlugin)
Vue.use(TooltipPlugin)
Vue.use(ModalPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDpGxb1y_1wDhpa_daBqy1pGWOB8EHDu6k',
    libraries: 'places'
  }
})
Vue.use(VueHighcharts)
Vue.use(DatePicker)
Vue.use(VueCookies)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
