import router from '../../router/index'
// import axios from 'axios'
const state = {
  tableGroup: [],
  dualSource: [],
  dualDestination: [],
  groups: [] // array[]
}
const mutations = {
  SET_GROUP_TABLE (state) {
    if (state.tableGroup.length) return
    const invLength = router.history.current.query.invLen
    state.tableGroup = []
    state.dualSource = []
    this.state.factoryData.forEach(item => {
      if (router.history.current.query.siteName === item.factoryName) {
        for (let i = 1; i < invLength + 1; i++) {
          for (let j = 1; j < invLength + 1; j++) {
            // tableGroup
            const obj = {
              '群組': 0,
              'INV 名稱': `INV${('0' + i).slice(-2)}`,
              'MPPT': `MPPT${('0' + j).slice(-2)}`,
              '電流 (A)': item.mppt_a[i - 1][j - 1]
            }
            state.tableGroup.push(obj)
            // tableGroup

            // dualSource
            const concatValue = `INV${('0' + i).slice(-2)}_MPPT${('0' + j).slice(-2)}`
            const listsObj = {
              name: concatValue,
              selected: false,
              mpptA: item.mppt_a[i - 1][j - 1]
            }
            state.dualSource.push(listsObj)
            // dualSource
          }
        }
      }
    })
  },
  HANDLE_DUAL_LISTS (state, payload) {
    // state.dualSource.sort((a, b) => {
    //   const [a1, a2] = a.name.split('_')
    //   const [b1, b2] = b.name.split('_')
    //   const A = a1.substr(3) * 10 + a2.substr(4) * 1
    //   const B = b1.substr(3) * 10 + b2.substr(4) * 1
    //   return A - B
    // })
    state.dualSource = payload.source
    state.dualDestination = payload.destination
  },
  ADD_GROUP (state) {
    // 清空 tableGroup
    state.tableGroup = []
    const ary = []
    state.dualDestination.forEach((item, idx) => {
      ary.push({ name: item.name, mpptA: item.mpptA, selected: item.selected, '群組': state.groups.length + 1 })
    })
    state.dualDestination.splice(0)
    state.groups.push(ary)

    // tableGroup
    state.groups.forEach((subAry, idx) => {
      subAry.forEach(item => {
        const obj = {
          '群組': idx + 1,
          'INV 名稱': `${item.name.split('_')[0]}`,
          'MPPT': `${item.name.split('_')[1]}`,
          '電流 (A)': item.mpptA
        }
        state.tableGroup.push(obj)
      })
    })
    // tableGroup
  },
  CANCEL_GROUP (state, payload) {
    console.log('CANCEL_GROUP payload: ', payload)
    payload.item.forEach(obj => {
      const dest = {
        name: obj.name,
        selected: false,
        mpptA: obj.mpptA
      }
      state.dualDestination.push(dest)
    })
    state.groups.splice(payload.idx, 1)
    // tableGroup
    state.tableGroup = state.tableGroup.filter(item => {
      // if (payload.idx + 1 === item['群組']) {
      //   // state.tableGroup.splice(idx, 1) 刪掉後順序會變，不能用splice
      // }
      return item['群組'] !== payload.item[0]['群組']
    })
    // tableGroup
  }
}
const actions = {
  // async setGroupTable ({ commit }) {
  //   const data = await axios.get('/api/pvstring/mppta')
  //   console.log(data)
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
