const { DateTime } = require('luxon')
const CryptoJS = require('crypto-js')

const dbDataHandle = (num, to = 2) => {
  try {
    if (isNaN(num) || num === -Infinity || num === Infinity || num === null || num === undefined || num === false || num < 0) return 0
    // num = 0.024
    let strNum = (typeof num === 'string') ? num : num.toString() // '0.024'
    let dotIndex = strNum.indexOf('.') + 1 // 2
    let result = ''
    let i = 0
    let j = ''

    const autoSettingPos = () => {
      const numAfterDot = strNum.slice(dotIndex, to + dotIndex) // '02'
      if (num > 0 && num < 1 && Number(numAfterDot) === 0) {
        to++
        autoSettingPos()
      }
    }
    autoSettingPos()

    // 10.15
    for (i; i < strNum.length; i++) {
      if (strNum[i] !== '.') {
        result += strNum[i]
      } else {
        result += (to === 0) ? '' : strNum[i]
        j = i
      }

      if (i === (j + to)) break
    }

    return result * 1
  } catch (err) {
    console.log(err)
    return 0
  }
}

const handleIRRArray = (irr) => {
  const type = Object.prototype.toString.call(irr).slice(8, -1).toLowerCase()
  if (irr === undefined || irr === null) return
  if (type && type === 'number') return irr
  if (type && type === 'array') return irr.length ? irr.reduce((a, b) => a + b) / irr.length : irr
  if (type && type === 'object') return irr.avg ? irr.avg : 0
}

const timeDiffNow = (time, unit = 'minutes') => {
  const now = DateTime.local()
  const dataTime = DateTime.fromISO(time).minus({ hour: 8 }) // diff() has bug so we -8h

  return now.diff(dataTime, unit).toObject()
}

/**
 *
 * @param {object} stateData state.factoryData []object
 * @param {object} updateData telemetry data single factory data object
 * @param  {string} args data arguments key and will be updating factoryData
 */
const updateFactoryData = (stateData, updateData, ...args) => {
  for (const arg of args) {
    stateData[arg] = updateData.detail[arg]
  }
}

const calculatePR = (cap, acp, IRR) => {
  if (cap && !isNaN(acp) && !isNaN(IRR)) {
    const sumCap = cap.reduce((a, b) => a + b)
    const powerHour = dbDataHandle(acp / sumCap)
    const irr = dbDataHandle(IRR)
    let PR = dbDataHandle(dbDataHandle(powerHour / irr) * 100, 0)
    if (PR >= 100) PR = 100
    return PR
  } else return 0
}

const decryptData = (data) => {
  const secret = process.env.VUE_APP_ENCRYPT_SECRET
  const decryptBytes = CryptoJS.AES.decrypt(data, secret)
  return JSON.parse(decryptBytes.toString(CryptoJS.enc.Utf8))
}

export {
  dbDataHandle,
  timeDiffNow,
  updateFactoryData,
  calculatePR,
  handleIRRArray,
  decryptData
}
