import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../lang/lang'
// import { auth, updatedToken } from '../http/msalMethods'
import { DateTime } from 'luxon'
import store from '../store/index'
import NotFound from '../views/NotFound.vue'
import { logoutApi } from '../api'

Vue.use(VueRouter)

// 解决编程式路由往同一地址跳转时会报错的情况
// 無 cookies auth_token 時，導回登入頁會報錯
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => err)
}

let routes = [
  {
    path: '/',
    redirect: `${i18n.locale}/realtime`
  },
  {
    path: '/:lang',
    name: 'Lang Not Found',
    component: NotFound
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  },
  {
    path: '/:lang',
    component: {
      name: 'langRouterView',
      render (c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/Login.vue')
      },
      {
        path: 'tvscreen',
        name: 'tvscreen',
        component: () => import('../views/TvScreen.vue'),
        meta: {
          // authorize: [Role.Whole_Admin, Role.Admin],
          release: true,
          zhTw: '展示畫面(電視牆)'
        }
      },

      {
        path: 'cookieerror',
        name: 'cookieerror',
        component: () => import('../views/CookieError.vue'),
        meta: { release: false }
      },
      {
        path: 'realtime',
        name: 'realtime',
        component: () => import('../views/Realtime.vue'),
        meta: {
          release: false,
          zhTw: '即時資訊',
          keepAlive: true
        }
      },
      {
        path: 'foreignfactories',
        name: 'foreignfactories',
        component: () => import('../views/ForeignFactories.vue'),
        meta: {
          release: true,
          zhTw: '外來監控資訊'
        }
      },
      {
        path: 'factorystatus',
        name: 'factorystatus',
        component: () => import('../views/FactoryStatus.vue'),
        meta: {
          release: true,
          zhTw: '案場狀態'
        }
      },
      {
        path: 'historyalarms',
        name: 'historyalarms',
        component: () => import('../views/HistoryAlarms.vue'),
        meta: {
          release: true,
          zhTw: '告警事件'
        }
      },
      {
        path: 'usermanage',
        name: 'usermanage',
        component: () => import('../views/UserManage.vue'),
        meta: {
          release: true,
          zhTw: '使用者管理'
        }
      },
      {
        path: 'groupmanage',
        name: 'groupmanage',
        component: () => import('../views/GroupManage.vue'),
        meta: {
          release: true,
          zhTw: '群組管理'
        }
      },
      {
        path: 'emailsend',
        name: 'emailsend',
        component: () => import('../views/EmailSend.vue'),
        meta: {
          release: true,
          zhTw: '信件補發'
        }
      },
      {
        path: 'multiplefactoryanalysis',
        name: 'multiplefactoryanalysis',
        component: () => import('../views/MultipleFactoryAnalysis.vue'),
        meta: {
          release: true,
          zhTw: '跨案場分析'
        }
      },
      {
        path: 'rawdatadownload',
        name: 'rawdatadownload',
        component: () => import('../views/RawDataDownload.vue'),
        meta: {
          release: true,
          zhTw: '原始資料下載'
        }
      },
      {
        path: 'raanalysis',
        name: 'raanalysis',
        component: () => import('../views/RaAnalysis.vue'),
        meta: {
          release: true,
          zhTw: 'RA 資料分析'
        }
      },
      {
        path: 'kpireport',
        name: 'kpireport',
        component: () => import('../views/KpiReport.vue'),
        meta: {
          release: true,
          zhTw: 'KPI 每月報告'
        }
      },
      {
        path: 'sortsetting',
        name: 'sortsetting',
        component: () => import('../views/SortSetting.vue'),
        meta: {
          release: true,
          zhTw: '案場排序'
        }
      },
      {
        path: 'fuqiangxindemo',
        name: 'fuqiangxindemo',
        component: () => import('../views/FuQiangXinDemo.vue'),
        meta: {
          release: true,
          zhTw: '富強鑫展示畫面'
        }
      },
      {
        path: 'tvwalllooping',
        name: 'tvwalllooping',
        component: () => import('../views/TvWallLooping.vue'),
        meta: {
          release: true, // 出現於群組管理選項
          zhTw: '電視牆輪播'
        }
      },
      {
        path: 'sitecomparation',
        name: 'sitecomparation',
        component: () => import('../views/SiteComparation.vue'),
        meta: { release: false }
      },
      {
        path: 'fangde',
        name: 'fangde',
        component: () => import('../views/FangDe.vue'),
        meta: {
          release: false
        }
      },
      {
        path: 'fuqiangxin',
        name: 'fuqiangxin',
        component: () => import('../views/FuQiangXin.vue'),
        meta: {
          release: false
        }
      },
      {
        path: 'linechart',
        name: 'linechart',
        component: () => import('../views/LineChart.vue'),
        meta: {
          release: false
        }
      },
      {
        path: 'siteinfo',
        name: 'siteinfo',
        component: () => import('../views/SiteInfo.vue'),
        children: [
          {
            path: 'siteinfodetail',
            name: 'siteinfodetail',
            component: () =>
              import('../components/SiteInfoDetail/SiteInfoDetail.vue'),
            meta: {
              release: true,
              zhTw: '案場資訊'
            }
          },
          {
            path: 'devicestatus',
            name: 'devicestatus',
            component: () => import('../components/DeviceStatus.vue'),
            meta: {
              release: true,
              zhTw: '裝置即時資訊'
            }
          },
          {
            path: 'devicehistory',
            name: 'devicehistory',
            component: () => import('../components/DeviceHistory.vue'),
            meta: {
              release: true,
              zhTw: '裝置歷史資料'
            }
          },
          {
            path: 'dataanalysis',
            name: 'dataanalysis',
            component: () => import('../components/DataAnalysis.vue'),
            meta: {
              release: true,
              zhTw: '資料分析'
            }
          },
          {
            path: 'mpptanalysis',
            name: 'mpptanalysis',
            component: () => import('../components/MpptAnalysis.vue'),
            meta: {
              release: true,
              zhTw: 'MPPT分析'
            }
          },
          {
            path: 'dataexcel',
            name: 'dataexcel',
            component: () => import('../components/DataExcel.vue'),
            meta: {
              release: true,
              zhTw: '設計資訊'
            }
          },
          {
            path: 'investmentanalysis',
            name: 'investmentanalysis',
            component: () => import('../components/InvestmentAnalysis.vue'),
            meta: {
              release: true,
              zhTw: '投資分析'
            }
          },
          {
            path: 'monthlyreport',
            name: 'monthlyreport',
            component: () => import('../components/MonthlyReport.vue'),
            meta: {
              release: true,
              zhTw: '案場報表'
            }
          },
          {
            path: 'otherinformation',
            name: 'otherinformation',
            component: () => import('../components/OtherInformation.vue'),
            meta: {
              release: true,
              zhTw: '其他資訊'
            }
          },
          {
            path: 'deviceinfo',
            name: 'deviceinfo',
            component: () => import('../components/DeviceInfo.vue'),
            meta: { release: false }
          },
          {
            path: 'pvstringfailureanalysis',
            name: 'pvstringfailureanalysis',
            component: () =>
              import('../components/PvStringFailureAnalysis.vue'),
            meta: {
              release: false
            },
            children: [
              {
                path: 'powerfee',
                name: 'powerfee',
                component: () =>
                  import('../components/PvStringComponents/PowerFee.vue')
              },
              {
                path: 'groupconfig',
                name: 'groupconfig',
                component: () =>
                  import('../components/PvStringComponents/GroupConfig.vue')
              },
              {
                path: 'circlechart',
                name: 'circlechart',
                component: () =>
                  import('../components/PvStringComponents/CircleChart.vue')
              },
              {
                path: 'queryhistorydata',
                name: 'queryhistorydata',
                component: () =>
                  import(
                    '../components/PvStringComponents/QueryHistoryData.vue'
                  )
              }
            ]
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    window.location.reload()
  }
})
router.beforeEach(async (to, from, next) => {
  if (to.name === 'login' && JSON.parse(localStorage.getItem('userDetail'))) {
    await logoutApi()
      .then(() => {
        localStorage.removeItem('userDetail')
      })
      .catch((err) => console.log(err))
  }

  if (to.name !== 'login') {
    if (JSON.parse(localStorage.getItem('userDetail'))) {
      const userDetail = JSON.parse(localStorage.getItem('userDetail'))
      store.commit('ADD_USER_DETAIL', userDetail)
    } else {
      return next({ name: 'login' })
    }
  }

  if (to.name !== 'login' && store.state.roleArray.length === 0) {
    await store.dispatch('getAllGroups')
  }

  // 有 userDetail displayName -> update role 群組權限
  if (store.state.userDetail.displayName) {
    store.commit('UPDATE_ROLE_ARRAY')
  }

  // 展示案場電視牆 / 電視牆輪播 權限 (上方 UPDATE_ROLE_ARRAY -> 取得 rolePermissionArray)
  if (to.name === 'tvscreen' || to.name === 'tvwalllooping') {
    if (!store.state.rolePermissionArray.includes(to.name)) {
      Vue.swal.fire('無此權限')
      return next({ name: 'realtime' })
    } else {
      store.state.currentName = to.name
      return next()
    }
  }

  if (to.name === 'realtime') {
    store.state.currentName = to.name
    if (!store.state.realTimeRePostBeginTime) {
      store.state.realTimeRePostBeginTime = DateTime.local()
    } else {
      const now = DateTime.local()
      const dataTime = DateTime.fromISO(store.state.realTimeRePostBeginTime, {
        zone: 'Asia/Taipei'
      })
      const diff = now.diff(dataTime, 'minutes').toObject()
      if (diff.minutes >= 5) {
        store.state.realTimeRePostBeginTime = null
        store.state.realTimeDataReload = true
      }
    }
    return next()
  } else {
    if (to.name === 'tvscreen') {
      store.state.currentName = to.name
      return next()
    }
    if (to.query.siteName && to.name !== 'tvscreen') {
      if (store.state.factoryInfo.length === 0) {
        await store.dispatch('asyncInfo')
      }
    }
    store.state.currentName = to.name
    return next()
  }
})
export { routes }
export default router
