<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <img src="../assets/acme-logo.png" />
    <div class="h2 mt-3">找不到頁面!</div>
    <a href="/">返回首頁?</a>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
