import axios from 'axios'
import router from '../router/index'

axios.interceptors.response.use(
  (config) => {
    return config
  },
  async (error) => {
    try {
      console.log('error obj in axios interceptor response', error)
      if (error.response.status && error.response.status === 401) {
        localStorage.removeItem('userDetail')
        router
          .push({ path: 'login' })
          .catch((err) => console.log('router push has error', err))
      } else if (error?.message?.includes('status code 500')) {
        return Promise.reject(error)
      } else {
        return error.response
      }
    } catch (err) {
      console.log('catch error in axios interceptors response', err)
    }
  }
)

export default axios
