/**
 * 依據物件裡有重複的值，從陣列中移除重複物件
 * 用法: removeDuplicates(originData, "key")
 *
 * @param {Array} originalArray - 目標陣列
 * @param {String} prop - 用來判斷的 物件的 key
 * @returns 移除重複物件後的陣列(重複的物件會留下一個)
 */

// export const removeDuplicates = (originalArray, prop) => {
//   let newArray = []
//   let lookupObject = {}

//   for (let i in originalArray) {
//     lookupObject[originalArray[i][prop]] = originalArray[i]
//   }

//   for (let i in lookupObject) {
//     newArray.push(lookupObject[i])
//   }
//   return newArray
// }

// 優化
export const removeDuplicates = (originalArray, prop) => {
  const lookupObject = {}

  for (const item of originalArray) {
    lookupObject[item[prop]] = item
  }

  return Object.values(lookupObject)
}
