import router from '../../router/index'

const state = {
  deviceStatusChartOptions: {
    chart: {
      backgroundColor: 'white',
      height: 300,
      marginTop: 50
    },
    title: '',
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 30
    },
    xAxis: {
      categories: [],
      crosshair: false
    },
    yAxis: [
      {
        title: '', // 不顯示 y values
        // title: {
        //   text: '累計發電量 (kWh)'
        // },
        labels: {
          enabled: false // 不顯示 y 刻度
        },
        min: 0,
        opposite: true
      },
      {
        title: '',
        // title: {
        //   text: '今日發電量 (kWh)'
        // },
        labels: {
          enabled: false
        },
        min: 0
      },
      {
        title: '',
        // title: {
        //   text: '交流/直流發電功率 (kW)'
        // },
        labels: {
          enabled: false
        },
        gridLineWidth: 0,
        min: 0
      }
    ],
    plotOptions: {
      series: {
        // threshold: 1000,
        // stacking: 'normal',
        dataLabels: {
          enabled: true
        },
        softThreshold: false
      },
      column: {
        dataLabels: {
          enabled: true
          // format: '{ point.y : .1f }'
        }
      }
    },
    series: [
      {
        data: [],
        type: 'column',
        name: '累積發電量',
        yAxis: 0,
        tooltip: {
          valueSuffix: ' (kWh)'
        }
      },
      {
        data: [],
        type: 'line',
        name: '今日發電量',
        yAxis: 1,
        tooltip: {
          valueSuffix: ' (kWh)'
        }
      },
      {
        data: [],
        type: 'line',
        name: '交流發電功率',
        yAxis: 2,
        tooltip: {
          valueSuffix: ' (kW)'
        }
      },
      {
        data: [],
        type: 'line',
        name: '直流發電功率',
        yAxis: 2,
        tooltip: {
          valueSuffix: ' (kW)'
        }
      }
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
              y: 0
            }
          }
        }
      ]
    }
  },
  tableField: [],
  tableData: [],
  dataTime: ''
}

const mutations = {
  SET_TABLE_FIELD (state, payload) {
    state.tableField = payload
  },
  SET_CHART_X_AXIS (state, payload) {
    state.deviceStatusChartOptions.xAxis.categories = payload
  },
  CLEAR_CHART_X_AXIS (state) {
    state.deviceStatusChartOptions.xAxis.categories = []
  }
}

const actions = {
  handleTableField ({ commit }, showOnlyPV) {
    let tableField = [
      {
        key: 'INV 名稱',
        sortable: true,
        stickyColumn: true,
        isRowHeader: true
      },
      {
        key: '連線狀態',
        sortable: true
      }
    ]

    if (!showOnlyPV) {
      const addTableField = [
        '累積發電量 (kWh)',
        '今日發電量 (kWh)',
        '直流發電功率 (kW)',
        '交流發電功率 (kW)',
        'INV 效率 (%)',
        '頻率 (Hz)',
        'Vab 電壓 (V)',
        'Vbc 電壓 (V)',
        'Vca 電壓 (V)',
        'A相電流 (A)',
        'B相電流 (A)',
        'C相電流 (A)'
      ].map((title) => ({
        key: title,
        label: title,
        sortable: true
      }))

      tableField.push(...addTableField)
    }

    const pvLen = parseInt(router.history.current.query.pvLen)
    for (let i = 1; i <= pvLen; i++) {
      tableField.push(
        { key: `PV${i}電壓 (V)`, sortable: true },
        { key: `PV${i}電流 (A)`, sortable: true }
      )
    }

    commit('SET_TABLE_FIELD', tableField)
  },
  setXAxisCategories ({ commit }) {
    const invLen = parseInt(router.history.current.query.invLen)
    const xAxisCategories = Array.from(
      { length: invLen },
      (_, idx) => `INV${String(idx + 1).padStart(2, '0')}`
    )

    commit('SET_CHART_X_AXIS', xAxisCategories)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
